import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasTemplates)?_c(VContainer,{staticClass:"px-0"},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VRow,{attrs:{"align":"center"}},[(!_vm.hideChildSelector)?_c(VCol,[_c(VBtn,{attrs:{"text":"","block":"","large":"","disabled":_vm.loading},on:{"click":function($event){return _vm.openSelector(_vm.childSelectorDialogId)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("palette")]),_vm._v(" Color Options ")],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp || _vm.hideChildSelector)?_c(VSpacer):_vm._e(),_c(VCol,[_c(VBtn,{attrs:{"text":"","block":"","large":"","disabled":_vm.loading},on:{"click":function($event){return _vm.openSelector(_vm.dialogId)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("folder-open")]),_vm._v(" Change Template ")],1)],1)],1)],1),_c('modal',{attrs:{"id":_vm.currentDialogId,"title":_vm.title},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VContainer,{staticClass:"pa-0"},[_c(VRow,[(!_vm.isChildTemplateView)?_c(VCol,{staticClass:"text-body-2",attrs:{"cols":"12","md":"8"}},[_c(VBanner,{attrs:{"shaped":"","icon":"exclamation-triangle","icon-color":"warning"}},[_vm._v(" Changing your template will regenerate your content. "),_c('br'),_vm._v(" You may lose any changes you have made. ")])],1):_c(VSpacer),_c(VCol,{staticClass:"text-right align-self-end",attrs:{"cols":"12","md":"4"}},[_c(VBtn,{attrs:{"color":"primary","large":"","disabled":!_vm.canApply},on:{"click":_vm.apply}},[_vm._v(" Apply ")])],1)],1)],1)]},proxy:true}],null,false,1085190178)},[_c(VRow,{staticClass:"pt-2"},_vm._l((_vm.currentTemplates),function(template){return _c(VCol,{key:template.id,staticClass:"template",attrs:{"cols":"6","md":_vm.columnSpan}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VSheet,{staticClass:"rounded template-item",class:{
                            'is-selected': _vm.isSelected(template)
                        },staticStyle:{"height":"100%"},attrs:{"elevation":hover || _vm.isSelected(template) ? 5 : 1},on:{"click":function($event){return _vm.select(template)}}},[_c(VImg,{attrs:{"src":_vm.getTemplateThumbnailUrl(template),"max-height":_vm.previewHeight,"height":_vm.previewHeight}}),_c('div',{staticClass:"py-1 text-caption text-center",class:{
                                'primary--text':
                                    hover || _vm.isSelected(template)
                            }},[_vm._v(" "+_vm._s(template.name)+" ")])],1)]}}],null,true)})],1)}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }