import { render, staticRenderFns } from "./ATemplateSelector.vue?vue&type=template&id=57814854&scoped=true&"
import script from "./ATemplateSelector.vue?vue&type=script&lang=ts&"
export * from "./ATemplateSelector.vue?vue&type=script&lang=ts&"
import style0 from "./ATemplateSelector.vue?vue&type=style&index=0&id=57814854&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57814854",
  null
  
)

export default component.exports