<template>
    <v-container v-if="hasTemplates" class="px-0">
        <v-card elevation="2">
            <v-row align="center">
                <v-col v-if="!hideChildSelector">
                    <v-btn
                        text
                        block
                        large
                        :disabled="loading"
                        @click="openSelector(childSelectorDialogId)"
                    >
                        <v-icon left>palette</v-icon>
                        Color Options
                    </v-btn>
                </v-col>
                <v-spacer
                    v-if="$vuetify.breakpoint.smAndUp || hideChildSelector"
                />
                <v-col>
                    <v-btn
                        text
                        block
                        large
                        :disabled="loading"
                        @click="openSelector(dialogId)"
                    >
                        <v-icon left>folder-open</v-icon>
                        Change Template
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <modal :id="currentDialogId" :title="title">
            <v-row class="pt-2">
                <v-col
                    v-for="template in currentTemplates"
                    :key="template.id"
                    cols="6"
                    :md="columnSpan"
                    class="template"
                >
                    <v-hover v-slot="{ hover }">
                        <v-sheet
                            class="rounded template-item"
                            :class="{
                                'is-selected': isSelected(template)
                            }"
                            :elevation="hover || isSelected(template) ? 5 : 1"
                            style="height: 100%"
                            @click="select(template)"
                        >
                            <v-img
                                :src="getTemplateThumbnailUrl(template)"
                                :max-height="previewHeight"
                                :height="previewHeight"
                            />
                            <div
                                class="py-1 text-caption text-center"
                                :class="{
                                    'primary--text':
                                        hover || isSelected(template)
                                }"
                            >
                                {{ template.name }}
                            </div>
                        </v-sheet>
                    </v-hover>
                </v-col>
            </v-row>
            <template #actions>
                <v-container class="pa-0">
                    <v-row>
                        <v-col
                            v-if="!isChildTemplateView"
                            cols="12"
                            md="8"
                            class="text-body-2"
                        >
                            <v-banner
                                shaped
                                icon="exclamation-triangle"
                                icon-color="warning"
                            >
                                Changing your template will regenerate your
                                content.
                                <br />
                                You may lose any changes you have made.
                            </v-banner>
                        </v-col>
                        <v-spacer v-else />
                        <v-col
                            cols="12"
                            md="4"
                            class="text-right align-self-end"
                        >
                            <v-btn
                                color="primary"
                                large
                                :disabled="!canApply"
                                @click="apply"
                            >
                                Apply
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </modal>
    </v-container>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

import type { SlideshowTemplate } from '@/types/Slideshow';
import { VideoTemplate, VideoTemplateType } from '@/types/Video';

const ATemplateSelectorProps = Vue.extend({
    name: 'ATemplateSelector',
    props: {
        templates: {
            type: Array as PropType<SlideshowTemplate[]>,
            required: true
        },
        activeTemplate: {
            type: Object as PropType<VideoTemplate>,
            default() {
                return {
                    id: 0,
                    parent_template_id: 0,
                    type: 0
                };
            }
        },
        childHeight: {
            type: [Number, String],
            default() {
                78;
            }
        },
        height: {
            type: [Number, String],
            default() {
                return 116;
            }
        },
        path: {
            type: String,
            default() {
                return 'slideshow';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});
@Component({
    components: {
        Modal
    }
})
export default class ASlideshowTemplateSelector extends ATemplateSelectorProps {
    _uid!: number;

    currentSelectedTemplateId = 0;

    currentDialogId = this.dialogId;

    get id() {
        return this._uid;
    }

    get hasTemplates() {
        return !!this.templates.length;
    }

    get dialogId() {
        return `template-selector-${this.id}`;
    }

    get childSelectorDialogId() {
        return `child-template-selector-${this.id}`;
    }

    get isChildTemplateView() {
        return this.currentDialogId === this.childSelectorDialogId;
    }

    get canApply() {
        return this.currentSelectedTemplateId !== this.activeTemplate.id;
    }

    get previewHeight() {
        if (this.isChildTemplateView) {
            return this.childHeight;
        }
        return Number(this.height);
    }

    get columnSpan() {
        return 2;
    }

    get title() {
        let type = 'Template';
        if (this.isChildTemplateView) {
            type = 'Color';
        }
        return `Select ${type}`;
    }

    get parentTemplates() {
        // sort in DESC i.e VideoTemplateType.LINEAR_GRADIENT_ONE
        // (has child options) comes first
        return this.templates
            .filter(this.isParentTemplate)
            .sort((a, b) => b.type - a.type);
    }

    get childTemplates() {
        const parentTemplateId = this.mapTemplateId(
            this.activeTemplate?.parent_template_id || this.activeTemplate.id
        );
        return this.templates.filter(t => {
            let tptid = t.parent_template_id;
            if (t.parent_template_id) {
                tptid = this.mapTemplateId(t.parent_template_id);
            }
            const isChildTemplate = tptid && tptid === parentTemplateId;
            const tId = this.mapTemplateId(t.id);
            return isChildTemplate || tId === parentTemplateId;
        });
    }

    get currentTemplates() {
        if (this.isChildTemplateView) {
            return this.childTemplates;
        }
        return this.parentTemplates;
    }

    get hideChildSelector() {
        const videoTemplate = this.activeTemplate;
        // hide for template types that has no child(color) option
        if (videoTemplate.type !== VideoTemplateType.LINEAR_GRADIENT_ONE) {
            return true;
        }
        const parentTemplateId =
            videoTemplate?.parent_template_id || videoTemplate?.id;
        if (!parentTemplateId) {
            return true;
        }
        return this.getChildTemplateIds(parentTemplateId).length === 0;
    }

    getChildTemplateIds(parentTemplateId: number) {
        parentTemplateId = this.mapTemplateId(parentTemplateId);
        return this.templates
            .filter(t => {
                return t.parent_template_id === parentTemplateId;
            })
            .map(t => t.id);
    }

    openSelector(dialogId: string) {
        this.currentSelectedTemplateId = this.activeTemplate.id;
        this.currentDialogId = dialogId;
        this.$store.dispatch('modal/open', this.currentDialogId);
    }

    closeSelector() {
        this.$store.dispatch('modal/close', this.currentDialogId);
    }

    getTemplateThumbnailUrl(template: SlideshowTemplate) {
        const templateId = this.mapTemplateId(template.id);
        let filename = `${templateId}`;
        if (this.isChildTemplateView && this.isParentTemplate(template)) {
            filename = `${templateId}_child`;
        }
        return `/new_ui/img/${this.path}/templates/${filename}.png`;
    }

    isSelected(template: SlideshowTemplate) {
        const currentSelectedId = this.mapTemplateId(
            this.currentSelectedTemplateId || this.activeTemplate.id
        );
        const currentSelectedTemplate = this.templates.find(
            t => t.id === currentSelectedId
        );
        if (
            currentSelectedTemplate?.parent_template_id &&
            !this.isChildTemplateView
        ) {
            return currentSelectedTemplate.parent_template_id === template.id;
        }
        return currentSelectedId === template.id;
    }

    select(template: SlideshowTemplate) {
        this.currentSelectedTemplateId = template.id;
    }

    apply() {
        this.$emit('change', this.currentSelectedTemplateId);
        this.closeSelector();
    }

    isParentTemplate(template: SlideshowTemplate) {
        return !template.parent_template_id;
    }

    mapTemplateId(templateId: number) {
        type TemplateIdMap = {
            [key: number]: number;
        };

        // we deprecated 9600 series of Slideshows but we still need to
        // map them to the active 9700 series, this way we render the images,
        // show 9700 series as child templates for existing amps using 9600s
        const TEMPLATE_ID_MAP: TemplateIdMap = {
            '9600': 9700,
            '9601': 9701,
            '9602': 9702,
            '9603': 9703,
            '9604': 9704,
            '9605': 9705,
            '9606': 9706,
            '9607': 9707,
            '9608': 9708,
            '9609': 9709,
            '9610': 9710,
            '9611': 9711,
            '9612': 9712,
            '9613': 9713,
            '9614': 9714,
            '9615': 9715
        };
        return TEMPLATE_ID_MAP[templateId] ?? templateId;
    }
}
</script>

<style lang="scss" scoped>
.template::v-deep {
    .template-item {
        border: 3px solid transparent;
        padding: 0;

        &:not(.is-selected) {
            cursor: pointer;
        }

        &.is-selected {
            border-color: $primary-color;
        }
    }
}
</style>
